$(document).ready(function(){

    var section = $('[data-image-src]');
    var testimonialsSlider = $('.owl-carousel');
    var video = $('.hero__video');
    var videoModal = $('.js-modal-video');
    var btnNext = $('.testimonials__next-button');
    var btnPrev = $('.testimonials__prev-button');
    var hero = $('.hero--bg');
    var $form = $('.subscribe-form');

    function register($form) {
        $form.find('button[type="submit"]').text('Sending...');
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            cache: false,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            error: function () {
                alert('Could not connect to the registration server. Please try again later.')
            },
            success: function (data) {
                if (data.result === 'success') {
                    // Yeahhhh Success
                    console.log(data.msg)
                } else {
                    // Something went wrong, do something to notify the user.
                    console.log(data.msg)

                }
            }
        })
    }

    if ($form.length > 0) {
        $form.find('button[type="submit"]').bind('click', function (event) {
            if (event) event.preventDefault();
            register($form)
        })
    }

    section.each(function(){
        var attr = $(this).attr('data-image-src');

        if (typeof attr !== typeof undefined && attr !== false) {
            $(this).css('background-image', 'url('+attr+')');
        }
    });

    if(testimonialsSlider.length) {

        testimonialsSlider.owlCarousel({
            items: 1,
            autoplay: true,
            loop: true,
            dots: false
        });
    }

    btnNext.on('click', function(){
        testimonialsSlider.trigger('next.owl.carousel');
    });

    btnPrev.on('click', function(){
        testimonialsSlider.trigger('prev.owl.carousel');
    });


    if(video.length) {

        var iframe = $('iframe');
        var player = new Vimeo.Player(iframe);

        video.find('.video-thumbnail__btn').on('click', function(e){
            e.preventDefault();
            $(this).parent().addClass('d-none');
            player.play();
        });
    }

    if(videoModal.length) {
        new ModalVideo('.js-modal-video');
    }

    $('a[href*=\\#]').on('click', function(event){
        event.preventDefault();
        $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
    });

    if(hero.length) {

        var height = hero.find('.hero__illustration').height();

        hero.find('.match-height').css('height', height + 54);
    }
});

$(window).resize(function(){

    var hero = $('.hero--bg');

    if(hero.length) {

        var height = hero.find('.hero__illustration').height();

        hero.find('.match-height').css('height', height + 54);
    }
});

$(document).scroll(function() {

    var navbar = $('.show-on-scroll');
    var navbar2 = $('.show-on-scroll-2');

    if ($(document).scrollTop() >= 250) {
        navbar.removeClass('d-none').addClass('animate');
        navbar2.addClass('animate');
    } else {
        navbar.addClass('d-none').removeClass('animate');
        navbar2.removeClass('animate');
    }

    var position = $(this).scrollTop();
    var offset = $(document).height() - $(window).height();
    var featuresSection = $(".features--2");

    if(featuresSection.length && $(window).width() >= 992) {

        featuresSection.css({
            "opacity": position / offset,
            "margin-top": "-" + position / offset * 150 + "px"
        });
    }
});
